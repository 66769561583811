import React from "react";
import Color from "tinycolor2";

import Logo from "../content/img/logo.svg";
import logo from "../content/img/logo.png";
import style from './css/headerStyle.scss';

require("../content/pdf/CV_Maxim_Schoemaker_2024.pdf");

const links = [
  { text: "--+ @ : ", url: "mailto:maxim.schoemaker@gmail.com", urlText: "maxim.schoemaker" },
  { text: "--+ ig: ", url: "http://instagram.com/maximschoemaker/", urlText: "maximschoemaker" },
  { text: "--+ tw: ", url: "http://twitter.com/maximgifmaker", urlText: "maximgifmaker" },
  { text: "--+ cv: ", url: "/src/content/pdf/CV_Maxim_Schoemaker_2024.pdf", urlText: "cv.pdf" },
]

const Header = ({ backgroundColor, showControls }) => {
  // const backgroundColorRGB = (Color(backgroundColor) || Color('black')).toRgb();
  // const textColor = Color({ r: 255 - backgroundColorRGB.r, g: 255 - backgroundColorRGB.g, b: 255 - backgroundColorRGB.b, a: 1 });

  const smallLogo = window.innerWidth < 500;

  return (
    <div className={[style.container, !showControls && style.hideControls, smallLogo && style.portrait].join(' ')}>
      <div className={style.nameContainer}>

        <p className={style.name} /*style={{ color: textColor.toHexString() }}*/>> maxim schoemaker</p>
        <div className={style.contactContainer}>
          <img className={style.logo} src={logo} />
          {/* <Logo className={style.logo} /> */}


          {/* <d className={style.logo} src={logo} style={{ maskBoxImage: `url(${logo})`, backgroundColor: textColor }} /> */}
          <div className={style.linkContainer}>
            {links.map((link, i) =>
              <div className={style.link} key={i} style={{ /*color: textColor.toHexString(),*/ transitionDelay: i / 10 + "s" }}>{link.text}
                <a target="_blank" href={link.url}>{link.urlText}</a>
              </div>
            )}
          </div>
        </div>

      </div>
    </div >
  );
}

export default Header;