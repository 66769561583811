import React from "react";
import ReactDOM from "react-dom";
import Color from "tinycolor2";

import Slider, { Range } from 'rc-slider';

import style from './css/videoControlsStyle.scss';
console.log(style);


class VideoControls extends React.Component {
  constructor(props) {
    super(props);
  }

  getColor(group, selected) {
    const hue = (group * .1 + 0.4).mod(1);
    const sat = selected ? 0.75 : 0.5;
    const val = selected ? 1 : 0.75;
    return Color({ h: hue * 360, s: sat * 100, v: val * 100 });
  }

  render() {
    const { videos, videoIndex, onClickDot, backgroundColor } = this.props;

    const curVideo = videos[videoIndex];
    const backgroundColorRGB = (Color(backgroundColor) || Color(curVideo.backgroundColor)).toRgb();
    const textColor = Color({ r: 255 - backgroundColorRGB.r, g: 255 - backgroundColorRGB.g, b: 255 - backgroundColorRGB.b, a: 1 });

    const ctrlColor = textColor.clone().setAlpha(0.1);

    return (
      <div /*style={{ backgroundColor: ctrlColor.toRgbString() }}*/ className={style.controlContainer}>
        <div className={style.dotRangeContainer}>
          {
            videos.map((video, i) => {
              const selected = i === videoIndex;
              const adjacent = Math.abs(i - videoIndex) === 1;
              const color = this.getColor(video.groupId, selected).toHex8String();
              return (
                <div key={i} className={style.dotContainer}
                //onClick={() => onClickDot(i)}
                >
                  <div className={[
                    style.dot,
                    selected ? style.selectedDot : null,
                    adjacent ? style.adjacentDot : null
                  ].join(' ')} style={{ backgroundColor: color }} />
                </div>
              );
            })
          }

          {/* <Slider className={style.range} trackStyle={{ height: "100%" }} min={0} max={(videos.length - 1)} step={0.5}
              onChange={onClickDot}
            /> */}
          <input type="range" className={style.range} min={0} max={videos.length - 1} step={1}
            onChange={(evt) => onClickDot(parseInt(evt.target.value))}
            onKeyDown={(evt) => evt.preventDefault()}
          />

        </div>
      </div>
    );
  }
}

export default VideoControls;