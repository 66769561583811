import React from "react";
import style from './css/backgroundStyle.scss';
console.log(style);

const Background = ({ backgroundColor, topColor, bottomColor, leftColor, rightColor, content }) => {
  const ratio = content ? (content.naturalWidth || content.videoWidth) / (content.naturalHeight || content.videoHeight) : 1;
  const contentHeight = Math.min(window.innerWidth / ratio, window.innerHeight);
  const contentWidth = Math.min(window.innerHeight * ratio, window.innerWidth);
  let bgHeight = Math.ceil((window.innerHeight - contentHeight) / 2);
  let bgWidth = Math.ceil((window.innerWidth - contentWidth) / 2);

  return (
    <div className={style.backgroundColor} style={{ backgroundColor: backgroundColor }}>
      {/* <canvas ref={(canvas) => this.bgCanvas = canvas} width={50} height={50} className={style.bgCanvas} /> */}
      {bgHeight > 0 &&
        <div className={style.backgroundColorPart} style={{ backgroundColor: topColor, height: "50%", top: 0 }} />
      }
      {bgHeight > 0 &&
        <div className={style.backgroundColorPart} style={{ backgroundColor: bottomColor, height: "50%", bottom: 0 }} />
      }
      {bgWidth > 0 &&
        <div className={style.backgroundColorPart} style={{ backgroundColor: leftColor, width: "50%", left: 0 }} />
      }
      {bgWidth > 0 &&
        <div className={style.backgroundColorPart} style={{ backgroundColor: rightColor, width: "50%", right: 0 }} />
      }
    </div>
  );
}

export default Background;