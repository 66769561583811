import React from 'react';

let groupIndex = 0;
let id = 0;

export const groups = [
  {
    id: id++,
    name: "Polish folk art",
  },
  {
    id: id++,
    name: "Golden Ratio Flower",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Video Feedback",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Distance Sine",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Curves",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Kolam",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Beziers",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Flocking Visualiser",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Christmas",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Simon Restrepo Collab",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Loaders",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Chladni",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Light sim",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Icosahedron",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Textures",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Gradient Candy",
    // text: () => <span>
    //   <p>Gradient candy.
    //   </p>
    // </span >
  },
  {
    id: id++,
    name: "Signals",
    text: () => <span>
      <p>Signals is a series based on visualizing the signals that I use throughout all of my animations.
      </p>
    </span >
  },
  {
    id: id++,
    name: "Gradient Shader",
    text: () => <span>
      <p>This project uses a shader to compute circular gradients. These gradient's are then morphed by signals forming shapes.
      </p>
    </span >
  },
  {
    id: id++,
    name: "Circle Stacks",
    text: () => <span>
      <p>This project uses a stack of circles where each circle is confined within its parent circle.
        Moving outside of the parent circle will budge the parent circle to keep its child inside.
        <br /><br />
        After the initial render I decided to try it out with webcam input.
        The top level circle is centered around the center of where your face is detected.
      </p>
    </span >
  },
  {
    id: id++,
    name: "Fractal Screen",
    text: () => <span>
      <p>This project tries to use fractal subdivision to dynamically render different levels of detail of a shape.
      </p>
    </span >
  },
  {
    id: id++,
    name: "Oh when the ray's come marching in!",
    text: () => <span>
      <p>I am developing a ray marching setup that I can use for making fractals and goopy geometric shapes. I've only recorded one video yet, but I'm hoping to continue on this setup soon!
      </p>
    </span >
  },
  {
    id: id++,
    name: "Making Waves",
    text: () => <span>
      <p>Making Waves is a series based on manipulating wave frequency in order to show patterns.
        These paterns range from lines, geometric shapes and even webcam input!
      </p>
    </span >
  },
  {
    id: id++,
    name: "70s Fractal",
    text: () => <span>
      <p>The 70's fractal is a series based on an artwork hanging in my home:</p>
      <img src={require("./img/fractal_photo.jpg")} />
      <p>
        It forms a fractal by creating smaller and smaller squares inside itself,
        where the variation in width and height is based on the fractal depth.
        The colour choice and line thickness give a kindof 70s feel :)
      </p>
    </span >
  },
  {
    id: id++,
    name: "LED Project(ion)",
    text: () => <span>
      <p>A project done in collaboration with <a href="https://www.instagram.com/flavour_flavz/" target="_blank">Flavia Terzian</a>.
        We set out to create 3 LED structures to be displayed at festivals for people to hangout in and around.
        I worked on writing a simulation which could be used to generate animations for the LED setup.
      </p>
      <p>I captured these animations during the development of the simulation to showcase the abilities of my setup.
        Abilities include:
      </p>
      <p>
        -- making geometricy shaped cutouts on the sides of a box.<br />
        -- Rendering LEDs along a specified path, for example along the edges of the geometric cutouts.<br />
        -- Projecting a 2d animations on to the LEDS to generate the illusion of a screen.<br />
      </p>

      {/* <ul>
        <li><p>making geometricy shaped cutouts on the side of a box.</p></li>
        <li><p>Rendering LEDs along a specified path, for example along the edges of the geometric cutouts.</p></li>
        <li><p>Projecting a 2d animations on to the LEDS to generate the illusion of a screen.</p></li>
      </ul> */}
    </span >
  },
  {
    id: id++,
    name: "Prosecco",
  },
  {
    id: id++,
    name: "Triangle Fractals",
  },
  {
    id: id++,
    name: "L-Systems",
  },
  {
    id: id++,
    name: "Tentacles",
  },
  {
    id: id++,
    name: "Neon Artefacts",
  },
  {
    id: id++,
    name: "Diffusion Reactions",
  },
  {
    id: id++,
    name: "Line Draw",
  },
  {
    id: id++,
    name: "Pixel Sorting",
  },
  {
    id: id++,
    name: "Edge Detection",
  },
];

export const videos = [
  {
    url: require('./vid/jula bd 2022.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/set.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/flowers.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/colour.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/shape.mp4'),
    backgroundColor: 'white',
    groupId: groupIndex,
  },

  {
    url: require('./vid/displace2.mp4'),
    backgroundColor: 'white',
    groupId: ++groupIndex,
  },

  {
    url: require('./vid/distSine.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },

  {
    url: require('./vid/jagged1.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/6_rings1.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/3_rings3.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/2_rings2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/curve_candy2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/curve_arcade1.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/curve_screen3.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/curve_solid2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/curve_solid_lighter1.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/curve_ghost3.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/heart.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/graffiti.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/earth.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/cyber.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/aubergine.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/arctic.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/ball_controls_14.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/physics10.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/physics4.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/kolam_fade_3.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/kolam_fade_2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/kolam_fade_1_3.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_64_WaveRandom2.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/gradient_36_WaveRandom1.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_9_WaveRandom2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_12_WaveRandom1.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_6_WaveRandom2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_3_WaveRandom2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/bezierTwirlsFinalFaster.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/swarm_8.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/swarm.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/cell_aut_18.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/hex_loop_wait_colour.mp4'),
    // backgroundColor: 'rgba(32,30,32)',
    setBackgroundColor: true,
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/rings_watermark.mp4'),
    // backgroundColor: 'rgba(32,30,32)',
    setBackgroundColor: true,
    groupId: groupIndex,
  },
  {
    url: require('./vid/collab_fractal_watermark.mp4'),
    // backgroundColor: 'rgba(32,30,32)',
    setBackgroundColor: true,
    groupId: groupIndex,
  },
  {
    url: require('./vid/wiper.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/chladni_swoop_lighter.mp4'),
    setBackgroundColor: 'loop',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/chladni_woosh.mp4'),
    setBackgroundColor: true,
    groupId: groupIndex,
  },
  {
    url: require('./vid/bright_long_fade_tri.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/quadratic_bright.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/1_bounce_bright.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/bezier_bulb.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/bezier_reflect_wave.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/bezier_reflect_alternate.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/doublePappus.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/pappusCloudBounce.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/pappusCloud2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/pappusCloud.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/pappus.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/bend_reflect_3.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/bend_reflect_2.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/bend_reflect_1.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/ice_cream_reflect.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/dish_reflect.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/lenses_lighter_fade.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/prism.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/isocahedron_particles.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/isocahedron_explode.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/sequin_snake_bit_bigger.mp4'),
    backgroundColor: 'black',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/wobble_wobble.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/dance_wave.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/stud_swirl.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/dance_floor.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/rings_3.mp4'),
    setBackgroundColor: 'loop',
    groupId: groupIndex,
  },
  {
    url: require('./vid/rings_2.mp4'),
    setBackgroundColor: 'loop',
    groupId: groupIndex,
  },
  {
    url: require('./vid/rings_1.mp4'),
    setBackgroundColor: 'loop',
    groupId: groupIndex,
  },
  {
    url: require('./vid/swirl.mp4'),
    setBackgroundColor: 'loop',
    groupId: groupIndex,
  },
  {
    url: require('./vid/pixel_rainbow.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/classy.mp4'),
    backgroundColor: 'white',
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/shape_black_5_gradient_neon.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/shape_black_5_gradient_retro.mp4'),
    backgroundColor: 'black',
    groupId: groupIndex,
  },
  {
    url: require('./vid/shape_white_4_gradient_fade_in.mp4'),
    backgroundColor: 'white',
    groupId: groupIndex,
  },
  {
    url: require('./vid/signal_window.mp4'),
    backgroundColor: "rgb(36, 114, 211)",
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/signal_shader.mp4'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_spheres.mp4'),
    backgroundColor: "black",
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/gradient_flowers.mp4'),
    // backgroundColor: "black",
    setBackgroundColor: "loop",
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_squares.mp4'),
    // backgroundColor: "black",
    setBackgroundColor: "loop",
    groupId: groupIndex,
  },
  {
    url: require('./vid/gradient_lines.mp4'),
    // backgroundColor: "black",
    setBackgroundColor: "loop",
    groupId: groupIndex,
  },
  {
    url: require('./vid/face_trip_wave.mp4'),
    // backgroundColor: "black",
    setBackgroundColor: true,
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/face_trip_circle.mp4'),
    // backgroundColor: "black",
    setBackgroundColor: true,
    groupId: groupIndex,
  },
  {
    url: require('./vid/circles.mp4'),
    // backgroundColor: "rgb(224, 161, 180)",
    setBackgroundColor: "loop",
    groupId: groupIndex,
  },
  {
    url: require('./vid/triangle_screen.mp4'),
    // backgroundColor: "orange",
    setBackgroundColor: true,
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/blob_morph.mp4'),
    backgroundColor: "black",
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/webcam_waves.mp4'),
    backgroundColor: "black",
    groupId: ++groupIndex,
  },
  {
    url: require('./vid/wave_shader.mp4'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./vid/wave_screen.mp4'),
    // backgroundColor: "black",
    setBackgroundColor: true,
    groupId: groupIndex,
  },
  {
    url: require('./vid/wave_stacks.mp4'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./vid/black1.mp4'),
    backgroundColor: "black",
    groupId: ++groupIndex,
    // text: () => <span>
    //   <p>This rendering animates over the fractal depth and dimension properties, creating a pulsing inward and outward motion.
    //     By varying the corner radius of the squares and composing the corners oposite eachother,
    //     the animation retains a line symetry throughout its morphing.
    //   </p>
    // </span>
  },
  {
    url: require('./vid/All_3_x2.mp4'),
    // backgroundColor: "black",
    groupId: ++groupIndex,
    setBackgroundColor: "loop",
  },
  {
    url: require('./vid/morph_cube_shapes_best_bob_final.mp4'),
    // backgroundColor: "black",
    groupId: groupIndex,
    setBackgroundColor: "loop",
  },
  {
    url: require('./vid/morph_cube_2_lights_light.mp4'),
    // backgroundColor: "rgba(10,10,10)",
    groupId: groupIndex,
    setBackgroundColor: "loop",
  },
  {
    url: require('./vid/LED_crawl_dark.mp4'),
    // backgroundColor: "black",
    setBackgroundColor: true,
    groupId: groupIndex
  },
  {
    url: require('./vid/project_final_final.mp4'),
    backgroundColor: "black",
    groupId: groupIndex
  },
  {
    url: require('./vid/sphere_lighting.mp4'),
    backgroundColor: "black",
    groupId: groupIndex
  },
  {
    url: require('./vid/duo_fit_blur_better.mp4'),
    backgroundColor: "black",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./gifs/black11.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
    style: {
      maxWidth: "500px",
      maxHeight: "500px",
    }
  },
  {
    url: require('./vid/twirl_final_smooth 1080x1080.mp4'),
    backgroundColor: "black",
    groupId: ++groupIndex,
  },
  {
    url: require('./gifs/black0.gif'),
    backgroundColor: "black",
    groupId: ++groupIndex,
  },
  {
    url: require('./gifs/black9.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black10.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./gifs/black3.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./gifs/black1.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black4.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black5.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./vid/black0.mp4'),
    backgroundColor: "black",
    groupId: ++groupIndex
  },
  {
    url: require('./gifs/black2.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./gifs/black6.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black7.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black17.gif'),
    backgroundColor: "black",
    groupId: ++groupIndex,
  },
  {
    url: require('./gifs/black19.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black20.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black13.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black18.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./gifs/black12.gif'),
    backgroundColor: "black",
    groupId: ++groupIndex,
  },
  {
    url: require('./gifs/black14.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black15.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/black16.gif'),
    backgroundColor: "black",
    groupId: groupIndex,
  },
  {
    url: require('./gifs/white0.gif'),
    backgroundColor: "white",
    groupId: ++groupIndex,
    style: {
      maxWidth: "500px",
      maxHeight: "500px",
    }
  },
  {
    url: require('./gifs/white1.gif'),
    backgroundColor: "white",
    groupId: groupIndex,
    style: {
      maxWidth: "500px",
      maxHeight: "500px",
    }
  },
  {
    url: require('./gifs/white2.gif'),
    backgroundColor: "white",
    groupId: groupIndex,
    style: {
      maxWidth: "500px",
      maxHeight: "500px",
    }
  },
  {
    url: require('./gifs/white3.gif'),
    backgroundColor: "white",
    groupId: groupIndex,
    off: true,
    style: {
      maxWidth: "500px",
      maxHeight: "500px",
    }
  },
  {
    url: require('./gifs/white4.gif'),
    backgroundColor: "white",
    groupId: groupIndex,
    style: {
      maxWidth: "500px",
      maxHeight: "500px",
    }
  },
  {
    url: require('./gifs/white5.gif'),
    backgroundColor: "white",
    groupId: groupIndex,
    style: {
      maxWidth: "500px",
      maxHeight: "500px",
    }
  },
  {
    url: require('./vid/mushroomBandsChroma.mp4'),
    backgroundColor: "white",
    groupId: ++groupIndex,
    off: true,
  },
  {
    url: require('./vid/drawPlanChroma.mp4'),
    backgroundColor: "white",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/magicMushroom.mp4'),
    backgroundColor: "white",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/afloat.mp4'),
    backgroundColor: "#FFD879",
    groupId: ++groupIndex,
    off: true,
  },
  {
    url: require('./vid/asplode.mp4'),
    backgroundColor: "#FFD879",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/ascend.mp4'),
    backgroundColor: "#FFD879",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/scales.mp4'),
    backgroundColor: "#FF86A3",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/waves.mp4'),
    backgroundColor: "#FF86A3",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/squares.mp4'),
    backgroundColor: "#FF86A3",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/cat_spike.mp4'),
    // backgroundColor: "black",
    groupId: groupIndex,
    setBackgroundColor: "loop",
    off: true,
  },
  {
    url: require('./vid/cat_spiral.mp4'),
    // backgroundColor: "black",
    groupId: groupIndex,
    setBackgroundColor: "loop",
    off: true,
  },
  {
    url: require('./vid/cat_twist.mp4'),
    // backgroundColor: "black",
    groupId: groupIndex,
    setBackgroundColor: "loop",
    off: true,
  },
  {
    url: require('./vid/luca_splosion.mp4'),
    // backgroundColor: "black",
    groupId: ++groupIndex,
    setBackgroundColor: "loop",
    off: true,
  },
  {
    url: require('./vid/cat_splosion.mp4'),
    // backgroundColor: "#0F70B7",
    groupId: groupIndex,
    setBackgroundColor: "loop",
    off: true,
  },
  {
    url: require('./vid/cat_swolesion.mp4'),
    // backgroundColor: "black",
    groupId: groupIndex,
    setBackgroundColor: true,
    off: true,
  },
  {
    url: require('./vid/rainbow_cube-converted.mp4'),
    backgroundColor: "black",
    groupId: ++groupIndex,
    off: true,
  },
  {
    url: require('./vid/rainbow_square-converted.mp4'),
    backgroundColor: "black",
    groupId: groupIndex,
    off: true,
  },
  {
    url: require('./vid/two_squares-converted.mp4'),
    backgroundColor: "black",
    groupId: groupIndex,
    off: true,
  },
].map((v, i) => {
  v.type = v.url.split('.').slice(-1)[0];
  v.id = i;
  return v;
});