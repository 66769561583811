import React from "react";
import ReactDOM from "react-dom";
import Color from "tinycolor2";
import debounce from "debounce";

import Slider, { Range } from 'rc-slider';
import VideoControls from './VideoControls';
import VideoRow from './VideoRow';
import VideoInfo from './VideoInfo';
import GroupInfo from './GroupInfo';

import style from './css/bottomPanelStyle.scss';
console.log(style);

const videoRowScrollHeight = 137;

class BottomPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anim: false,
      panelScrolling: false,
      panelOpen: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.panel.scrollTo({
        top: videoRowScrollHeight,
        behavior: 'smooth'
      });
    }, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.anim) {
      setTimeout(() => this.setState({ anim: false }), 1);
    }
    if (this.state.animGrp) {
      setTimeout(() => this.setState({ animGrp: false }), 1);
    }

    if (prevProps.videoIndex != this.props.videoIndex) {
      this.setState({ anim: true });
      if (prevProps.videos[prevProps.videoIndex].groupId != this.props.videos[this.props.videoIndex].groupId)
        this.setState({ animGrp: true });
    }
  }

  getColor(group, selected) {
    const hue = (group * .1 + 0.4).mod(1);
    const sat = selected ? 0.75 : 0.5;
    const val = selected ? 1 : 0.75;
    return Color({ h: hue * 360, s: sat * 100, v: val * 100 });
  }

  getGroup(id) {
    return groups.find((v) => v.id == id);
  }

  onPanelClick = (evt) => {
    //this.setState({ panelScrolling: !this.state.panelScrolling });
  }

  onGroupClick = (evt) => {
    if (this.panel) {
      let to = videoRowScrollHeight;
      if (Math.abs(this.panel.scrollTop - to) < 5)
        to = 0;
      this.panel.scrollTo({
        top: to,
        behavior: 'smooth'
      })
    }
  }

  onItemClick = (evt) => {
    if (this.panel) {
      const childrenHeight = Array.from(this.panel.childNodes).reduce((tot, node) => tot + node.offsetHeight, 0);
      let to = childrenHeight - this.panel.offsetHeight;
      if (Math.abs(this.panel.scrollTop - to) < 5)
        to = 0;
      this.panel.scrollTo({
        top: to,
        behavior: 'smooth'
      });
    }
  }

  activatePanel = (evt) => {
    // console.log("activate!");
    this.setState({
      panelScrolling: true
    });
  }

  deactivatePanel = (evt) => {
    // console.log("deactivate...");
    this.setState({
      panelScrolling: false
    });
  }

  onScrollPanel = (evt) => {
    if (evt.target === this.panel) {
      if (this.scrollTimeout)
        clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.deactivatePanel();
      }, 300);

      // const childrenHeight = Array.from(evt.target.childNodes).reduce((tot, node) => tot + node.offsetHeight, 0);
      // const panelOpen = evt.target.scrollTop / (childrenHeight - evt.target.offsetHeight);
      const panelOpen = Math.min(1, evt.target.scrollTop / (evt.target.offsetHeight * 0.5));
      this.props.panelOpen(panelOpen);

      this.setState({
        panelOpen: panelOpen
      });

    }
  }

  interpolateColors(color1, color2, ratio) {
    color1 = color1.toRgb();
    color2 = color2.toRgb();

    return Color({
      r: color1.r * ratio + color2.r * (1 - ratio),
      g: color1.g * ratio + color2.g * (1 - ratio),
      b: color1.b * ratio + color2.b * (1 - ratio),
    });
  }

  render() {
    const { videos, groups, videoIndex, onSelectedVideo, backgroundColor, showControls } = this.props;
    const { anim, animGrp, panelScrolling, panelOpen } = this.state;

    const curVideo = videos[videoIndex];
    // const backgroundColorRGB = (Color(backgroundColor) || Color(curVideo.backgroundColor)).toRgb();

    // const textColor = this.interpolateColors(
    //   Color('white'),
    //   Color({ r: 255 - backgroundColorRGB.r, g: 255 - backgroundColorRGB.g, b: 255 - backgroundColorRGB.b, a: 1 }),
    //   Math.min(1, panelOpen * 2)
    // );

    const { groupId, group } = curVideo;

    // let groupColor = this.interpolateColors(
    //   Color('white'),
    //   this.getColor(groupId, false),
    //   panelOpen
    // );
    let groupColor = this.getColor(groupId, false);

    // const panelBackgroundColor = Color('black');
    // panelBackgroundColor.setAlpha(panelOpen * 0.9);

    // const titelBackgroundColor = Color('black');
    // titelBackgroundColor.setAlpha(panelOpen == 1 ? 1 : panelOpen * 0.8 + 0.2);

    return (
      <div className={[
        style.controlAllContainer,
        !showControls && style.hideControls
      ].join(' ')}
        style={{ '--panelOpen': panelOpen }}
      >
        <div className={[style.panelContainer, panelScrolling ? style.scrollingPanelContainer : null].join(' ')}
          ref={(ref) => this.panel = ref}
          onScroll={this.onScrollPanel}
        >

          <div className={style.panelTopSpace}
            onTouchStart={this.deactivatePanel}
          />

          <div className={[style.panel].join(' ')}
            onMouseOver={this.activatePanel} onMouseLeave={this.deactivatePanel}
            //onPointerEnter={this.activatePanel} onPointerLeave={this.deactivatePanel}
            onTouchStart={this.activatePanel}
          // onTouchStart={this.activatePanel} onTouchEnd={this.deactivatePanel}
          >
            <div className={style.labelContainer} onClick={this.onPanelClick} >
              <div className={style.labelLimit}>
                <span onClick={this.onGroupClick} className={[style.label, style.labelGrp, animGrp ? style.labelAnim : null].join(' ')} style={{ color: groupColor.toRgbString() }}>~ {curVideo.group ? curVideo.group.name : `group ${curVideo.groupId}`}&nbsp;</span>
                <span onClick={this.onItemClick} className={[style.label, style.labelVideo, anim ? style.labelAnim : null].join(' ')} /*style={{ color: textColor.toRgbString() }}*/>> {curVideo.url}</span>
              </div>
            </div>

            <div className={style.panelText}>

              <div className={style.videoRow}>
                {panelOpen &&
                  <VideoRow
                    group={curVideo.group}
                    videos={videos}
                    videoIndex={videoIndex}
                    onClickVideo={onSelectedVideo}
                  />
                }
              </div>

              <div className={style.info}>
                <GroupInfo
                  group={curVideo.group}
                />
                <VideoInfo
                  video={curVideo}
                />
              </div>
            </div>

          </div>

        </div>

        <div className={style.videoControlsContainer} /*style={{ backgroundColor: panelBackgroundColor }}*/>
          <VideoControls
            videos={videos}
            videoIndex={videoIndex}
            onClickDot={onSelectedVideo}
          // backgroundColor={backgroundColor}
          />
        </div>
      </div>
    );
  }
}

export default BottomPanel;