
import React from "react";
import style from './css/spinnerStyle.scss';

const Spinner = ({ count }) =>
  <div className={style.container}>
    <div className={style.loadRing} />
    <div className={style.count}>{count ? count : ''}</div>
    {/* <div className={[style.loadRing, style.loadRing2].join(' ')} /> */}
    {/* <div className={[style.loadRing, style.loadRing3].join(' ')} /> */}
  </div>

export default Spinner;
