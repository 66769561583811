import React from "react";
import ReactDOM from "react-dom";

import VideoPlayer from "./components/VideoPlayer";
import { videos, groups } from "./content/videos-all";
import { BrowserRouter as Router, Route } from "react-router-dom";

console.log(groups);
console.log(videos);

class Index extends React.Component {
  render() {
    return <Router>
      {/* <Route exact path="/:i"> */}
      <VideoPlayer videos={videos} groups={groups} />
      {/* </Route> */}
    </Router>
  };
}

ReactDOM.render(<Index />, document.getElementById("index"));