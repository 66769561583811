exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".groupInfoStyle_groupBody_lw_j6EwhAKjHM4LR6ESZ6{overflow-x:hidden;text-overflow:ellipsis}.groupInfoStyle_videoRow_24fmjlbyoMcTfVrP-ep_aA{overflow-x:auto;white-space:nowrap}.groupInfoStyle_videoContainer_CKxyW-NaUnRwvHQnbKdjs{display:inline-block;position:relative;width:100px;height:100px;margin-right:15px;opacity:0.5}.groupInfoStyle_selected_1FOXub_Nx8V5RAkrED0pHR{opacity:1;border:1px solid white}.groupInfoStyle_text_sgnoxELMA1Pjddv6N1jC7{margin-top:35px}\n", "",{"version":3,"sources":["groupInfoStyle.scss"],"names":[],"mappings":"AAAA,gDAAW,iBAAiB,CAAC,sBAAsB,CAAC,gDAAU,eAAe,CAAC,kBAAkB,CAAC,qDAAgB,oBAAoB,CAAC,iBAAiB,CAAC,WAAW,CAAC,YAAY,CAAC,iBAAiB,CAAC,WAAW,CAAC,gDAAU,SAAS,CAAC,sBAAsB,CAAC,2CAAM,eAAe","file":"groupInfoStyle.scss","sourcesContent":[".groupBody{overflow-x:hidden;text-overflow:ellipsis}.videoRow{overflow-x:auto;white-space:nowrap}.videoContainer{display:inline-block;position:relative;width:100px;height:100px;margin-right:15px;opacity:0.5}.selected{opacity:1;border:1px solid white}.text{margin-top:35px}\n"]}]);

// Exports
exports.locals = {
	"groupBody": "groupInfoStyle_groupBody_lw_j6EwhAKjHM4LR6ESZ6",
	"videoRow": "groupInfoStyle_videoRow_24fmjlbyoMcTfVrP-ep_aA",
	"videoContainer": "groupInfoStyle_videoContainer_CKxyW-NaUnRwvHQnbKdjs",
	"selected": "groupInfoStyle_selected_1FOXub_Nx8V5RAkrED0pHR",
	"text": "groupInfoStyle_text_sgnoxELMA1Pjddv6N1jC7"
};