import React from 'react';

import style from './css/videoInfoStyle.scss';
console.log(style);

const VideoInfo = ({ video }) => {
  return (
    <div className={style.videoBody}>
      {video.text && (
        <div className={style.text}>
          <h1>{video.url}</h1>
          {video.text()}
        </div>
      )}
    </div>
  );
}

export default VideoInfo;