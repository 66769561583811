import React from 'react';
import PlayVideo from './PlayVideo';

import style from './css/videoRowStyle.scss';
console.log(style);

class VideoRow extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      this.scrollToPreview();
    }, 200);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.videoIndex != this.props.videoIndex) {
      this.scrollToPreview();
    }
  }

  scrollToPreview = () => {
    const { group, videos, videoIndex } = this.props;
    const curVideo = videos[videoIndex];
    const groupVideos = videos.filter((v) => v.groupId == group.id);
    const videoGroupIndex = groupVideos.indexOf(curVideo);

    // this.videoRow.childNodes[videoGroupIndex].scrollIntoView({
    //   behavior: 'smooth',
    // });

    this.videoRow.scrollTo({
      left: (videoGroupIndex - 1) * 115,
      behavior: 'smooth',
    });
  }

  render() {
    const { group, videos, videoIndex, onClickVideo } = this.props;
    const curVideo = videos[videoIndex];
    const groupVideos = videos.filter((v) => v.groupId == group.id);
    const videoGroupIndex = groupVideos.indexOf(curVideo);

    return (
      <div className={style.videoRow} ref={(ref) => this.videoRow = ref}>
        {groupVideos.map((v, i) => {
          const selected = i == videoGroupIndex;
          return (
            <div key={group.id + "_" + i} className={[style.videoContainer, selected ? style.selected : null].join(' ')}
              onClick={(evt) => onClickVideo(videos.indexOf(v))}
            >
              <PlayVideo video={v} autoPlay={false} preview={true} />
            </div>
          )
        })}
      </div>
    );
  }
}

export default VideoRow;