import React from 'react';
import PlayVideo from './PlayVideo';

import style from './css/groupInfoStyle.scss';
console.log(style);

class GroupInfo extends React.Component {


  render() {
    const { group } = this.props;

    return (
      <div className={style.groupBody}>
        {group.text != null &&
          (
            <div className={style.text}>
              <h1>{group.name}</h1>
              {group.text()}
            </div>
          )
        }
      </div>
    );
  }
}

export default GroupInfo;