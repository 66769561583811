
import React from "react";
import ReactDOM from "react-dom";
import Color from "tinycolor2";

import VideoControls from "./VideoControls";
import Background from "./Background";

import style from './css/playVideoStyle.scss';
import Spinner from "./Spinner";
console.log(style);

class PlayVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, loadProgress: 0, playProgress: 0, playing: false };

    //var canvas = document.createElement('canvas');
    // canvas.width = 10;
    // canvas.height = 1;
    //this.ctx = canvas.getContext('2d');
  }

  static getDerivedStateFromProps(props, state) {
    return {
      backgroundColor: state.backgroundColor || props.video.backgroundColor || 'black',
      topColor: state.topColor || props.video.backgroundColor || 'black',
      bottomColor: state.bottomColor || props.video.backgroundColor || 'black',
      leftColor: state.leftColor || props.video.backgroundColor || 'black',
      rightColor: state.rightColor || props.video.backgroundColor || 'black'
    }
  }

  componentDidMount() {
    const { video } = this.props;
    const { type } = video;

    this.mounted = true;

    this.onStopped();

    const { onContentLoad, onBackgroundColorChanged } = this.props;
    // if (onContentLoad)
    //   onContentLoad(true);
    if (onBackgroundColorChanged)
      onBackgroundColorChanged(video.backgroundColor || 'black');
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.interval);
    // if (this.content.pause) {
    //   console.log("this.content.pause");
    //   this.content.pause();
    // }

    // if (this.content.load) {
    //   // this.content.pause();
    //   this.content.src = "";
    //   this.content.load();
    //   if (this.state.loaded)
    //     console.log("unload!", this.props.video.url);
    // }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.loaded !== this.state.loaded) {
      const { onContentLoad } = this.props;
      if (onContentLoad)
        onContentLoad(this.content, this.state.loaded);
    }
    if (prevProps.video !== this.props.video) {
      this.onStopped();
    }

  }

  setupGifTimeout(duration) {
    const { onEnded } = this.props;
    this.setState({ loadProgress: 1 });

    if (onEnded) {
      const totalTime = Math.max((duration || 0) * 1000, 10000);
      console.log(duration, totalTime);
      const update = 100;
      let time = 0;

      clearInterval(this.interval);
      this.interval = setInterval(() => {
        time += update;
        const playProgress = time / totalTime;
        this.setState({ playProgress })
        this.onPlaying();
        if (playProgress >= 1) {
          this.onEnded();
          time = 0;
        }
      }, update);
    }
  }

  getImageAvg(img, x, y, avgWidth, avgHeight) {
    const ctx = this.canvas.getContext('2d');
    const { width, height } = ctx.canvas;

    ctx.drawImage(img,
      x, y, avgWidth, avgHeight,
      0, 0, width, height,
    );

    const { data } = ctx.getImageData(0, 0, width, height);
    let avgClr = [0, 0, 0, 0];
    data.forEach((val, i) => {
      avgClr[i % 4] += val;
    });
    const res = Color({
      r: avgClr[0] / (data.length / 4),
      g: avgClr[1] / (data.length / 4),
      b: avgClr[2] / (data.length / 4),
    })
    return res;
  }

  getBackgroundColor = (img) => {
    const imgWidth = img.width || img.videoWidth;
    const imgHeight = img.height || img.videoHeight;
    const rows = 5;
    const topColor = this.getImageAvg(img, 0, 0, imgWidth, rows);
    const bottomColor = this.getImageAvg(img, 0, imgHeight - rows, imgWidth, rows);
    const leftColor = this.getImageAvg(img, 0, 0, rows, imgHeight);
    const rightColor = this.getImageAvg(img, imgWidth - rows, 0, rows, imgHeight);
    const backgroundColor = Color.mix(Color.mix(topColor, bottomColor, 50), Color.mix(leftColor, rightColor, 50), 50);
    return { backgroundColor, topColor, bottomColor, leftColor, rightColor };
  }

  /*drawBgGradient = (clr1, clr2) => {
    const ctx = this.bgCanvas;

    var grd = ctx.createRadialGradient(75, 50, 5, 90, 60, 100);
    grd.addColorStop(0, clr1);
    grd.addColorStop(1, clr2);

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, ctx.width, ctx.height);
  }*/

  setBackgroundColor = (img, loop) => {
    if (this.mounted) {
      const {
        backgroundColor: oldBackgroundColor,
        topColor: oldTopColor,
        bottomColor: oldBottomColor,
        leftColor: oldLeftColor,
        rightColor: oldRightColor,
        preview,
      } = this.state;

      const { video } = this.props;

      let { backgroundColor, topColor, bottomColor, leftColor, rightColor } = this.getBackgroundColor(img);

      if (loop) {
        if (oldBackgroundColor) backgroundColor = Color.mix(Color(oldBackgroundColor), backgroundColor, 10);
        if (oldTopColor) topColor = Color.mix(Color(oldTopColor), topColor, 10);
        if (oldBottomColor) bottomColor = Color.mix(Color(oldBottomColor), bottomColor, 10);
        if (oldLeftColor) leftColor = Color.mix(Color(oldLeftColor), leftColor, 10);
        if (oldRightColor) rightColor = Color.mix(Color(oldRightColor), rightColor, 10);
      }

      this.setState({
        topColor: topColor.toHexString(),
        bottomColor: bottomColor.toHexString(),
        leftColor: leftColor.toHexString(),
        rightColor: rightColor.toHexString(),
        backgroundColor: backgroundColor.toHexString(),
      });

      video.backgroundColor = backgroundColor.toHexString();

      if (this.props.onBackgroundColorChanged && !preview)
        this.props.onBackgroundColorChanged(backgroundColor);

      if (loop)
        window.requestAnimationFrame(() => this.setBackgroundColor(img, true));
      // setTimeout(() => this.setBackgroundColor(img, true), 1000 / 30);
    }
  }

  onLoad = (evt) => {
    if (!this.state.loaded) {

      const { video, preview } = this.props;
      const { type } = video;

      if (type == 'mp4')
        this.content.playbackRate = 1;

      // if (type == 'gif')
      const { duration } = evt.target;
      this.setupGifTimeout(duration);

      if (video.setBackgroundColor != null && this.canvas)
        this.setBackgroundColor(evt.target, video.setBackgroundColor === "loop" && !preview);

      // else
      //   if (this.props.onBackgroundColorChanged && !preview)
      //     this.props.onBackgroundColorChanged(new Color(video.backgroundColor || 'black'));

      this.onPlaying();

      this.setState({
        loaded: true
      });

    }
  }

  onLoadProgress = (evt) => {
    // if (this.state.loadProgress < 1) {
    const { lengthComputable, total } = evt.nativeEvent;
    const { buffered, duration, loaded } = evt.target;
    this.content.playbackRate = 1;

    if (buffered.length > 0) {
      const loadProgress = buffered.end(buffered.length - 1) / duration;
      this.setState({ loadProgress })
    }
    // }
  }

  onTimeUpdate = (evt) => {
    // const oldPlayProgress = this.state.playProgress;

    // const { videoPlaying, onEnded } = this.props;
    // const { currentTime, duration } = evt.target;
    // const playProgress = currentTime / duration;
    // this.setState({ playProgress })
    this.onPlaying();

    // if (playProgress < oldPlayProgress)
    //   this.onEnded();
  }

  onEnded = () => {
    const { onEnded } = this.props;
    if (onEnded)
      onEnded();

    if (this.content.play)
      this.content.play();
  }

  onStalled = (evt) => {
    const { preview } = this.props;
    if (!preview)
      console.log("stalled!");
    this.onStopped();
    // this.setState({
    //   loaded: false
    // });
  }

  onSuspend = (evt) => {
    const { preview } = this.props;
    // if (!preview)
    //   console.log("suspended!", this.props.video.url);
    this.onStopped();
    // this.setState({
    //   loaded: false
    // });
  }

  onPlaying = () => {
    const { videoPlaying, preview } = this.props;
    const { playing } = this.state;
    if (videoPlaying)
      videoPlaying();
    this.setState({ playing: true });
  }

  onStopped = () => {
    const { videoStopped } = this.props;
    const { playing } = this.state;
    if (videoStopped)
      videoStopped();
    this.setState({ playing: false });
  }


  render() {
    const { video, className, fadeout, searching, autoPlay, showControls, preview } = this.props;
    const { backgroundColor, leftColor, rightColor, topColor, bottomColor, playing } = this.state;
    const { loaded, loadProgress, playProgress } = this.state;
    // const { playProgress } = this;
    const { type, url } = video;

    return (
      <div
        //style={{ backgroundColor: video.backgroundColor }}
        className={[
          style.container,
          fadeout ? style.fadeout : null,
          searching ? style.searching : null,
        ].join(' ')}>

        {/* <div className={style.loadingOverlay} >
          <Spinner />
        </div> */}
        <Background
          backgroundColor={backgroundColor}
          leftColor={leftColor}
          rightColor={rightColor}
          topColor={topColor}
          bottomColor={bottomColor}
          content={this.content}
        />

        {preview && !loaded &&
          < div className={style.spinnerContainer}>
            <Spinner
            ></Spinner>
          </div>
          // <div className={style.playProgressSmallContainer} style={{ opacity: loadProgress == 1 ? 0 : 0.5 }}>
          //   <div className={style.playProgressSmall} style={{ width: `${loadProgress * 100}%` }} />
          // </div>
        }

        {
          type === "mp4" &&
          <video ref={(content) => this.content = content} className={style.background} style={video.style}
            // onLoadedData={this.onLoad} onPlay={this.onLoad} onCanPlay={this.onLoad} onCanPlayThrough={this.onLoad}
            onCanPlay={this.onLoad}
            onStalled={this.onStalled}
            onSuspend={this.onSuspend}
            onProgress={this.onLoadProgress}
            //onLoadedData={this.onLoadProgress}
            loop={true}
            onTimeUpdate={this.onTimeUpdate}
            autoPlay={autoPlay} muted
            // onEnded={this.onEnded}
            preload="metadata"
            style={{ opacity: loaded > 0 ? 1 : 0 }}
            src={url}
            type="video/mp4"
          >
            {/* {mounted &&
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            } */}
          </video >
        }
        {
          type === "gif" &&
          <img ref={(content) => this.content = content} src={url} className={style.background} style={video.style}
            onLoad={this.onLoad}
          />
        }
        {
          !preview &&
          < div className={style.progressContainer} >
            <div className={style.loadProgress} style={{ width: `${loadProgress * 100}%`, opacity: !loaded ? 0.25 : 'calc(var(--showControls) * 0.25)' }} />
            <div className={style.playProgress} style={{ width: `${playProgress * 100}%`, opacity: !loaded ? 0.25 : 'calc(var(--showControls) * 0.25)' }} />
          </div>
        }


        <canvas ref={(ref) => this.canvas = ref} width={10} height={10} style={{ visibility: "hidden", position: "absolute", top: 0, width: "100px", height: "100px" }}></canvas>

      </div >
    );
  };
}

export default PlayVideo;