import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from 'react-router-dom';
import { Motion } from "react-motion";
import Color from "tinycolor2";

import Slider, { Range } from 'rc-slider';

import Spinner from "./Spinner";
import BottomPanel from "./BottomPanel";
import PlayVideo from "./PlayVideo";
import Background from "./Background";
import Header from "./Header";

import style from './css/style.scss';
console.log(style);

function clone(src) {
  return Object.assign({}, src);
}

Number.prototype.mod = function (n) {
  return ((this % n) + n) % n;
};

const getImageDataPixel = (imgData, x, y) => {
  x = Math.floor(x);
  y = Math.floor(y);
  const red = y * (imgData.width * 4) + x * 4;
  return new Color({ r: imgData.data[red], g: imgData.data[red + 1], b: imgData.data[red + 2], a: imgData.data[red + 3] / 255.0 });
}


const timeoutTime = 5;
const timeoutUnit = 0.1;

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.controlsPanelRef = React.createRef();
    this.controlsRef = React.createRef();

    this.state = {
      videos: [],
      playVideos: [],
      searching: false,
      videoIndex: JSON.parse(localStorage.getItem('videoIndex') || "0"),
      queueIndex: 80,
      panelOpen: 0,
      loaded: false,
      backgroundColor: new Color('black'), leftColor: new Color('black'), rightColor: new Color('black'), topColor: new Color('black'), bottomColor: new Color('black'),
      inverseColor: new Color('white'),
      showControls: false,
      loop: JSON.parse(localStorage.getItem('loop') || "false"),
      shuffle: JSON.parse(localStorage.getItem('shuffle') || "false"),
      fade: JSON.parse(localStorage.getItem('fade') || "true"),
      fullscreen: false,
      videoStoppedCounter: 0,
    };

    let params = new URLSearchParams(this.props.location.search);
    if (params.has('i'))
      this.state.videoIndex = JSON.parse(params.get('i'));
    if (params.has('loop'))
      this.state.loop = JSON.parse(params.get('loop'));
    if (params.has('shuffle'))
      this.state.shuffle = JSON.parse(params.get('shuffle'));
    if (params.has('fade'))
      this.state.fade = JSON.parse(params.get('fade'));
    if (params.has('portal')) {
      this.state.portal = JSON.parse(params.get('portal'));
      this.state.videoIndex = Math.floor(Math.random() * props.videos.length);
    }
  }

  shuffleQueue = [];

  componentDidMount() {

    this.queueVideo();
    // this.onSelectedVideo(0);
    this.engage();

    setTimeout(this.scrollControlsUp, 100);
    setTimeout(this.scrollControlsUp, 200);
    setTimeout(this.scrollControlsUp, 500);
    setTimeout(this.scrollControlsUp, 1000);
    this.populateShuffleQueue();
  }

  populateShuffleQueue() {
    const { videos } = this.state;
    const newShuffleQueue = videos.map((v, index) => ({ index, order: Math.random() })).sort((v1, v2) => v1.order - v2.order).map((v) => v.index);
    this.shuffleQueue.push(...newShuffleQueue);
  }

  scrollControlsUp = () => {
    const controlsPannel = this.controlsPanelRef.current;
    controlsPannel.scrollTo({
      top: controlsPannel.offsetHeight,
      behavior: 'smooth'
    })

  }

  static deriveVideos = (videos, groups) => {
    const onVideos = videos.filter((v) => !v.off && !(v.group && v.group.off));
    onVideos.forEach((v) => {
      v.group = groups.find((g) => g.id === v.groupId);
    });
    return onVideos;
  }

  static getDerivedStateFromProps(props, state) {
    return { videos: VideoPlayer.deriveVideos(props.videos, props.groups) }
  }

  updateQueryParams = () => {
    history.pushState({}, null, `?i=${this.state.videoIndex}${this.state.loop ? "&loop=true" : ""}${this.state.shuffle ? "&shuffle=true" : ""}`);
  }

  queueVideo = () => {
    this.setState((state) => {
      const playVideos = state.playVideos;
      const newVideo = clone(state.videos[state.videoIndex]);
      // newVideo.key = playVideos.length ? playVideos[0].key + 1 : 0;
      playVideos.unshift(newVideo);

      this.updateQueryParams();

      for (let i = 0; i < playVideos.length - 2; i++)
        playVideos.splice(playVideos.length - 1, 1);

      // if (playVideos.length > 1)
      setTimeout(() => {
        for (let i = 0; i < playVideos.length - 1; i++)
          playVideos.splice(playVideos.length - 1, 1);
        this.setState({ searching: false, playVideos });
      }, !state.fade ? 0 : (state.searching ? 100 : 3000));

      // this.nextVideo();
      const nextVideo = !state.shuffle
        ? state.videos[(state.videoIndex + 1).mod(state.videos.length)]
        : state.videos[state.nextVideoIndex];

      console.log("preloadReady: false");
      return {
        playVideos,
        nextVideo,
        //loaded: true,
        spinning: true,
        videoStoppedCounter: timeoutTime + 1,
        preloadReady: false,
      };
    });
  }


  componentDidUpdate(prevProps, prevState) {
    const { queueIndex, videoIndex, videos, loop, shuffle, fade } = this.state;

    if (prevState.queueIndex !== queueIndex) {
      if (shuffle) {
        if (queueIndex >= this.shuffleQueue.length)
          this.populateShuffleQueue();
        this.setState({
          videoIndex: this.shuffleQueue[queueIndex.mod(this.shuffleQueue.length)],
          nextVideoIndex: this.shuffleQueue[(queueIndex + 1).mod(this.shuffleQueue.length)],
        })
      }
    }
    if (prevState.videoIndex !== videoIndex) {
      localStorage.setItem('videoIndex', videoIndex);
      // this.startRandomVideoTimer();
      this.queueVideo();
    }

    if (prevState.fade !== fade) {
      localStorage.setItem('fade', fade);
    }
    if (prevState.loop !== loop) {
      localStorage.setItem('loop', loop);
      this.updateQueryParams();
    }
    if (prevState.shuffle !== shuffle) {
      localStorage.setItem('shuffle', shuffle);
      this.updateQueryParams();
    }
  }

  onSelectedVideo = (index) => {
    this.engage();
    this.setState({
      // queueIndex: index,
      videoIndex: index,
      searching: true,
    })
  }

  onBackgroundColorChanged = (color) => {

    const colorRGB = (new Color(color) || new Color('black')).toRgb();
    const inverseColor = Color({ r: 255 - colorRGB.r, g: 255 - colorRGB.g, b: 255 - colorRGB.b, a: 1 });

    // const colorHSL = (new Color(color) || new Color('black')).toHsl();
    // const inverseColor = Color.fromRatio({ h: 1 - colorHSL.h, s: 1 - colorHSL.s, l: 1 - colorHSL.l, a: 1 });

    this.setState({ backgroundColor: Color(color), inverseColor });
  }


  nextVideo = (add) => {
    const { queueIndex, videoIndex, videos, shuffle, preloadReady } = this.state;
    if (add)
      console.log("add", add);
    if (!shuffle)
      this.setState({ videoIndex: (videoIndex + (add || 1)).mod(videos.length) });
    else
      this.setState({ queueIndex: (queueIndex + (add || 1)) });
  }

  prevVideo = () => {
    const { queueIndex, videoIndex, videos, shuffle } = this.state;
    if (!shuffle)
      this.setState({ videoIndex: (videoIndex - 1).mod(videos.length) });
    else
      this.setState({ queueIndex: (queueIndex - 1) });
  }

  clickNextVideo = () => {
    this.engage();
    this.nextVideo();
    this.setState({ searching: true });
  }

  clickPrevVideo = () => {
    this.engage();
    this.prevVideo();
    this.setState({ searching: true });
  }

  videoEnded = () => {
    console.log("videoEnded");
    const { panelOpen, loop, shuffle, preloadReady } = this.state;
    if (!panelOpen && !loop) {
      this.nextVideo(preloadReady ? null : 2);
    }
  }

  engage = () => {
    this.setState({ showControls: true });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() =>
      this.setState({ showControls: false })
      , 2500);
  }

  disengage = () => {
    this.setState({ showControls: false });
    clearTimeout(this.timeout);
  }

  panelOpen = (open) => {
    this.setState({ panelOpen: open });
  }

  toggleFade = () => {
    this.setState({ fade: !this.state.fade });
  }
  toggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  }
  toggleShuffle = () => {
    this.setState({ shuffle: !this.state.shuffle });
  }
  toggleFullscreen = () => {
    const fullscreen = window.innerHeight != screen.height;
    if (fullscreen)
      document.documentElement.requestFullscreen();
    else
      document.exitFullscreen();
    this.setState({ fullscreen });
  }

  videoPlaying = () => {
    this.setState({ spinning: false, videoStoppedCounter: timeoutTime + 1, videoPlaying: true });
    clearTimeout(this.videoStoppedTimeout);
  }

  videoStopped = () => {
    this.setState({ spinning: true, videoPlaying: false })
    this.countVideoStopped();
  }

  countVideoStopped = () => {
    const { panelOpen } = this.state;
    clearTimeout(this.videoStoppedTimeout);
    this.videoStoppedTimeout = setTimeout(() => {
      if (!panelOpen && this.state.videoStoppedCounter <= 0) {
        this.nextVideo();
      } else {
        this.setState({ videoStoppedCounter: Math.max(0, (this.state.videoStoppedCounter || timeoutTime + 1) - timeoutUnit) });
        this.countVideoStopped();
      }
    }, 150);
  }

  onPreloadReady = () => {
    console.log("preloadReady");
    this.setState({ preloadReady: true });
  }

  render() {
    const { groups } = this.props;
    const { playVideos, nextVideo, searching,
      videoIndex, videos, loaded,
      backgroundColor, inverseColor, showControls,
      panelOpen, loop, shuffle, fade,
      spinning, videoStoppedCounter, videoPlaying,
      preloadReady, nextDown, prevDown, portal } = this.state;

    const sliderMax = Math.floor(window.devicePixelRatio * window.innerWidth / 60);

    const panelTextColor = Color.mix(inverseColor, Color('white'), panelOpen * 100);
    const panelColor = Color('black').setAlpha(panelOpen * 0.8);
    const panelHeaderColor = Color("transparent"); //Color('white').setAlpha(panelOpen * 0.9);
    const videoControlsColor = inverseColor.clone().setAlpha(0.1); //Color.mix(inverseColor.clone().setAlpha(0.1), Color('black').setAlpha(0.9), panelOpen * 100);

    return (
      <div
        tabIndex="0"
        className={[style.moviePlayer,
        !showControls && style.hideControls,
        panelOpen && style.panelOpen,
        ].join(' ')}
        onMouseMove={!this.touch && this.engage}
        onTouchStart={() => this.touch = true}
        // onScroll={this.engage}
        // onPointerDown={this.engage}
        onKeyDown={this.keyDown}
        onKeyUp={this.keyUp}
        style={{
          '--showControls': showControls ? 1 : 0,
          '--inverseColor': inverseColor.toHex8String(),
          '--backgroundColor': backgroundColor.toHex8String(),
          '--panelTextColor': panelTextColor.toHex8String(),
          '--panelColor': panelColor.toHex8String(),
          '--panelHeaderColor': panelHeaderColor.toHex8String(),
          '--videoControlsColor': videoControlsColor.toHex8String(),
        }}
      >

        <div>
          {playVideos.map((video, i) => {
            return <PlayVideo
              videoPlaying={i == 0 && this.videoPlaying}
              videoStopped={i == 0 && this.videoStopped}
              autoPlay
              showControls={showControls || panelOpen}
              onEnded={i == 0 && this.videoEnded}
              onBackgroundColorChanged={i == 0 && this.onBackgroundColorChanged}
              key={video.id}
              video={video}
              searching={searching}
              fadeout={i > 0} />;
          })}
          {/* {nextVideo && videoPlaying &&
            // < div style={{ width: 150, height: 150, position: 'relative', margin: 50, backgroundColor: 'var(--inverseColor)' }}>
            < div style={{ visibility: 'hidden' }}>
              < PlayVideo
                videoPlaying={!preloadReady && this.onPreloadReady}
                autoPlay={false}
                preview={true}
                showControls={false}
                // key={nextVideo.id}
                video={nextVideo}
                fadeout={false} />
            </div>
          } */}
        </div>

        <div className={style.loadingOverlay} style={{ opacity: videoStoppedCounter <= timeoutTime + 0.5 ? 1 : 0 }}>
          <Spinner count={!panelOpen && videoStoppedCounter <= timeoutTime && Math.ceil(videoStoppedCounter)} />
        </div>



        {/* <div className={style.range}>
          <Slider className={style.range} trackStyle={{ backgroundColor: 'gray' }} min={0} max={sliderMax} step={1}
            ref={(ref) => this.sliderRef = ref}
            handleStyle={{ backgroundColor: 'red' }}
            onChange={this.changeFrame}
            onBeforeChange={this.onBeforeChange}
            onAfterChange={this.onAfterChange}
          />
        </div> */}

        {!portal &&
          <React.Fragment>
            <div className={style.panelContainer} onClick={showControls ? this.disengage : this.engage}>
              <Header showControls={showControls || panelOpen} /*backgroundColor={backgroundColor}*/ />
              <BottomPanel showControls={showControls || panelOpen} panelOpen={this.panelOpen} videos={videos} groups={groups} videoIndex={videoIndex} onSelectedVideo={this.onSelectedVideo} /*backgroundColor={backgroundColor} */ />
            </div>

            <div onClick={this.clickPrevVideo} className={[style.prevButton, prevDown && style.active].join(' ')}>
              {/* &lt; */}
              &#123;
            </div>
            <div onClick={this.clickNextVideo} className={[style.nextButton, nextDown && style.active].join(' ')}>
              }
            </div>

            <div className={style.controlsPanel} /*style={{ color: inverseColor }}*/ ref={this.controlsPanelRef}>
              <div className={style.controlsContainer} style={{ height: `calc(100% + ${this.controlsRef.current && this.controlsRef.current.offsetHeight - 50}px)` }}>
                <div className={style.controls} ref={this.controlsRef}>
                  <div> <span className={[style.fadeButton, fade && style.buttonActive].join(' ')} onClick={this.toggleFade}>fade</span></div>
                  <div className={style.shuffleLoop}>
                    <span className={[style.loopButton, loop && style.buttonActive].join(' ')} onClick={this.toggleLoop}>loop</span>|
                    <span className={[style.shuffleButton, shuffle && style.buttonActive].join(' ')} onClick={this.toggleShuffle}>shuffle</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={style.fullscreenContainer}/* style={{ color: inverseColor }}*/ onClick={this.toggleFullscreen} >
              <span className={[style.loopButton, loop && style.buttonActive].join(' ')}>[]</span>
            </div>
          </React.Fragment>
        }

      </div >
    )
  };

  // onKeyDown = (evt) => {
  //   switch (evt.key) {
  //     case "Shift": {
  //       this.setState({ searching: true });
  //       break;
  //     }
  //   }
  // }

  // onKeyUp = (evt) => {
  //   switch (evt.key) {
  //     case "Shift": {
  //       this.setState({ searching: false });
  //       break;
  //     }
  //   }
  // }

  // onAfterChange = (evt) => {
  //   if (this.sliderRef)
  //     this.sliderRef.blur();
  //   this.setState({ searching: false });
  // }

  // onBeforeChange = (val) => {
  //   this.firstChange = true;
  // }

  // changeFrame = (val) => {
  //   let { offset, offsetIndex, videos } = this.state;

  //   if (this.firstChange) {
  //     offset = val;
  //     offsetIndex = this.state.videoIndex;
  //     this.setState({ offset, offsetIndex });
  //     this.firstChange = false;
  //   }

  //   const index = (offsetIndex + val - offset).mod(videos.length);

  //   this.setState({
  //     videoIndex: index,
  //     searching: true
  //   });
  // }

  // onMouseMove = (evt) => {
  //   const { searching, videos } = this.state;
  //   if (searching) {
  //     const index = Math.floor((evt.pageX / window.innerWidth) * videos.length);
  //     this.setState({
  //       videoIndex: index
  //     });
  //   }
  // }

  // startRandomVideoTimer = (length) => {
  //   if (this.interval)
  //     clearInterval(this.interval);
  //   this.interval = setTimeout(() => {
  //     this.randomVideo();
  //   }, length);
  // }

  keyDown = (evt) => {
    switch (evt.key) {
      case "ArrowLeft":
        // this.engage();
        this.setState({ prevDown: true });
        // this.prevVideo();

        // clearTimeout(this.prevDownTimeout);
        // this.prevDownTimeout = setTimeout(() => this.setState({ prevDown: false }), 100);
        break;
      case "ArrowRight":
        // this.engage();
        this.setState({ nextDown: true });
        // this.nextVideo();

        // clearTimeout(this.nextDownTimeout);
        // this.nextDownTimeout = setTimeout(() => this.setState({ nextDown: false }), 100);
        break;
    }
  }

  keyUp = (evt) => {
    switch (evt.key) {
      case "ArrowLeft":
        // this.engage();
        this.setState({ searching: true, prevDown: false });
        this.prevVideo();
        break;
      case "ArrowRight":
        // this.engage();
        this.setState({ searching: true, nextDown: false });
        this.nextVideo();
        break;
    }
  }

  // randomVideo = () => {
  //   this.setState({
  //     videoIndex: Math.floor(Math.random() * this.state.videos.length)
  //   });
  // }
}

export default withRouter(VideoPlayer);